var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-tabs',{staticClass:"has-background-white-ter",staticStyle:{"padding-top":"1rem","padding-bottom":"1rem"},attrs:{"size":"is-medium","type":"is-toggle-rounded","position":"is-centered"},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('b-tab-item',{staticClass:"tab-item-spacing",attrs:{"label":_vm.$t('On My Way'),"icon":"map-marked-alt","icon-pack":"fad"}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('router-link',{class:{
              disabled: !_vm.purchasedOmw || !(_vm.omwLookupAccess || _vm.adminAccess),
            },attrs:{"to":"/omwLookup"}},[_c('div',{staticClass:"notification is-info has-background-link",class:{
                disabled: !_vm.purchasedOmw || !(_vm.omwLookupAccess || _vm.adminAccess),
              }},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('On My Way')))]),_c('vue-fontawesome',{staticClass:"icon-layout",attrs:{"icon":['fad', 'map-marked-alt'],"size":"3x"}})],1)]),_c('br'),_c('div',{class:{
              disabled:
                !_vm.purchasedMarketing || !(_vm.marketingAccess || _vm.adminAccess),
            }},[_c('router-link',{class:{
                disabled:
                  !_vm.purchasedMarketing || !(_vm.marketingAccess || _vm.adminAccess),
              },attrs:{"to":"/marketing"}},[_c('div',{staticClass:"notification is-info has-background-link"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('Marketing Messages')))]),_c('vue-fontawesome',{staticClass:"icon-layout",attrs:{"icon":['fad', 'funnel-dollar'],"size":"3x"}})],1)])],1),_c('br'),_c('div',{class:{
              disabled:
                !_vm.purchasedMarketing || !(_vm.marketingAccess || _vm.adminAccess),
            }},[_c('router-link',{class:{
                disabled:
                  !_vm.purchasedMarketing || !(_vm.marketingAccess || _vm.adminAccess),
              },attrs:{"to":"/omw-kill-switch"}},[_c('div',{staticClass:"notification is-info has-background-link"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('Outage Config')))]),_c('vue-fontawesome',{staticClass:"icon-layout",attrs:{"icon":['fad', 'unlink'],"size":"3x"}})],1)])],1)],1),_c('div',{staticClass:"column"},[_c('router-link',{class:{
              disabled:
                !_vm.purchasedFeedback || !(_vm.feedbackAccess || _vm.adminAccess),
            },attrs:{"to":"/feedbackViewer"}},[_c('div',{staticClass:"notification is-info has-background-link",class:{
                disabled:
                  !_vm.purchasedFeedback || !(_vm.feedbackAccess || _vm.adminAccess),
              }},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('Customer Feedback')))]),_c('vue-fontawesome',{staticClass:"icon-layout",attrs:{"icon":['fad', 'comments'],"size":"3x"}})],1)]),_c('br'),(_vm.resourceConfigEnabled)?_c('router-link',{class:{
              disabled: !_vm.purchasedOmw || !(_vm.omwLookupAccess || _vm.adminAccess),
            },attrs:{"to":"/omwResourceConfig"}},[_c('div',{staticClass:"notification is-info has-background-link",class:{
                disabled: !_vm.purchasedOmw || !(_vm.omwLookupAccess || _vm.adminAccess),
              }},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('Engineer Config')))]),_c('vue-fontawesome',{staticClass:"icon-layout",attrs:{"icon":['fad', 'user-hard-hat'],"size":"3x"}})],1)]):_vm._e()],1),_c('div',{staticClass:"column"},[_c('router-link',{class:{
              disabled:
                !_vm.purchasedOmwStats || !(_vm.omwStatsAccess || _vm.adminAccess),
            },attrs:{"to":"/omwStats"}},[_c('div',{staticClass:"notification is-info has-background-link",class:{
                disabled:
                  !_vm.purchasedOmwStats || !(_vm.omwStatsAccess || _vm.adminAccess),
              }},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('On My Way Statistics')))]),_c('vue-fontawesome',{staticClass:"icon-layout",attrs:{"icon":['fad', 'chart-pie'],"size":"3x"}})],1)]),_c('br'),_c('router-link',{class:{
              disabled:
                !_vm.purchasedOmwStats || !(_vm.omwStatsAccess || _vm.adminAccess),
            },attrs:{"to":"/rebookStats"}},[_c('div',{staticClass:"notification is-info has-background-link",class:{
                disabled:
                  !_vm.purchasedOmwStats || !(_vm.omwStatsAccess || _vm.adminAccess),
              }},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('Rebook Statistics')))]),_c('vue-fontawesome',{staticClass:"icon-layout",attrs:{"icon":['fad', 'chart-pie'],"size":"3x"}})],1)])],1)])]),(_vm.purchasedDataLoaders || _vm.purchasedActivityMigrator)?_c('b-tab-item',{staticClass:"tab-item-spacing",attrs:{"label":_vm.$t('OFSC Data Management'),"icon":"database","icon-pack":"fad"}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('router-link',{class:{
              disabled:
                !_vm.purchasedDataLoaders || !(_vm.dataLoaderAccess || _vm.adminAccess),
            },attrs:{"to":"/loaders"}},[_c('div',{staticClass:"notification is-info has-background-link",class:{
                disabled:
                  !_vm.purchasedDataLoaders || !(_vm.dataLoaderAccess || _vm.adminAccess),
              }},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('OFSC Data Managers')))]),_c('vue-fontawesome',{staticClass:"icon-layout",attrs:{"icon":['fad', 'upload'],"size":"3x"}})],1)]),_c('br'),_c('router-link',{class:{
              disabled:
                !_vm.purchasedDataLoaders || !(_vm.dataLoaderAccess || _vm.adminAccess),
            },attrs:{"to":"/extractCalendars"}},[_c('div',{staticClass:"notification is-info has-background-link",class:{
                disabled:
                  !_vm.purchasedDataLoaders || !(_vm.dataLoaderAccess || _vm.adminAccess),
              }},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('Calendar Extract')))]),_c('vue-fontawesome',{staticClass:"icon-layout",attrs:{"icon":['fad', 'download'],"size":"3x"}})],1)]),_c('br'),_c('router-link',{class:{
              disabled:
                !_vm.purchasedDataLoaders || !(_vm.dataLoaderAccess || _vm.adminAccess),
            },attrs:{"to":"/extractResources"}},[_c('div',{staticClass:"notification is-info has-background-link",class:{
                disabled:
                  !_vm.purchasedDataLoaders || !(_vm.dataLoaderAccess || _vm.adminAccess),
              }},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('Resource Extract')))]),_c('vue-fontawesome',{staticClass:"icon-layout",attrs:{"icon":['fad', 'download'],"size":"3x"}})],1)])],1),_c('div',{staticClass:"column"},[_c('router-link',{class:{
              disabled:
                !_vm.purchasedActivityMigrator ||
                !(_vm.activityMigratorAccess || _vm.adminAccess),
            },attrs:{"to":"/activityMigrator"}},[_c('div',{staticClass:"notification is-info has-background-link",class:{
                disabled:
                  !_vm.purchasedActivityMigrator ||
                  !(_vm.activityMigratorAccess || _vm.adminAccess),
              }},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('Activity Migrator')))]),_c('vue-fontawesome',{staticClass:"icon-layout",attrs:{"icon":['fad', 'download'],"size":"3x"}})],1)]),_c('br'),_c('router-link',{class:{
              disabled:
                !_vm.purchasedInventoryManager ||
                !(_vm.inventoryAccess || _vm.adminAccess),
            },attrs:{"to":"/inventoryManager"}},[_c('div',{staticClass:"notification is-info has-background-link",class:{
                disabled:
                  !_vm.purchasedInventoryManager ||
                  !(_vm.inventoryAccess || _vm.adminAccess),
              }},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('Inventory Manager')))]),_c('p'),_c('vue-fontawesome',{staticClass:"icon-layout",attrs:{"icon":['fad', 'inventory'],"size":"3x"}})],1)])],1),_c('div',{staticClass:"column"},[_c('router-link',{class:{
              disabled:
                !_vm.purchasedRealTimeTravel ||
                !(_vm.realTimeTravelAccess || _vm.adminAccess),
            },attrs:{"to":"/rtt"}},[_c('div',{staticClass:"notification is-info has-background-link",class:{
                disabled:
                  !_vm.purchasedRealTimeTravel ||
                  !(_vm.realTimeTravelAccess || _vm.adminAccess),
              }},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('Real-Time Travel')))]),_c('vue-fontawesome',{staticClass:"icon-layout",attrs:{"icon":['fad', 'car-side'],"size":"3x"}})],1)]),_c('br'),_c('router-link',{class:{
              disabled: !_vm.purchasedPdflify || !(_vm.pdflifyAccess || _vm.adminAccess),
            },attrs:{"to":"/formSearch"}},[_c('div',{staticClass:"notification is-info has-background-link",class:{
                disabled:
                  !_vm.purchasedPdflify || !(_vm.pdflifyAccess || _vm.adminAccess),
              }},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('Forms PDFlify')))]),_c('vue-fontawesome',{staticClass:"icon-layout",attrs:{"icon":['fad', 'clipboard-check'],"size":"3x"}})],1)])],1)])]):_vm._e(),(_vm.purchasedIsochrone)?_c('b-tab-item',{staticClass:"tab-item-spacing",attrs:{"label":_vm.$t('Resource Coverage'),"icon":"street-view","icon-pack":"fad"}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('router-link',{class:{
              disabled:
                !_vm.purchasedIsochrone || !(_vm.isochroneAccess || _vm.adminAccess),
            },attrs:{"to":"/isochrone"}},[_c('div',{staticClass:"notification is-info has-background-dark",class:{
                disabled:
                  !_vm.purchasedIsochrone || !(_vm.isochroneAccess || _vm.adminAccess),
              }},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('Coverage Analytics')))]),_c('vue-fontawesome',{staticClass:"icon-layout",attrs:{"icon":['fad', 'street-view'],"size":"3x"}})],1)])],1),_c('div',{staticClass:"column"})])]):_vm._e(),_c('b-tab-item',{staticClass:"tab-item-spacing",attrs:{"label":_vm.$t('Administration'),"icon":"tools","icon-pack":"fad"}},[_c('div',{staticClass:"columns"},[(_vm.adminAccess || _vm.customerAdminAccess)?_c('div',{staticClass:"column"},[_c('router-link',{attrs:{"to":"/admin"}},[_c('div',{staticClass:"notification is-info has-background-link"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('User Admin')))]),_c('vue-fontawesome',{staticClass:"icon-layout",attrs:{"icon":['fad', 'lock'],"size":"3x"}})],1)])],1):_vm._e(),_c('div',{staticClass:"column"},[_c('router-link',{attrs:{"to":"/envAdmin"}},[_c('div',{staticClass:"notification is-info has-background-link"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('Environment Admin')))]),_c('vue-fontawesome',{staticClass:"icon-layout",attrs:{"icon":['fad', 'database'],"size":"3x"}})],1)])],1),_c('div',{staticClass:"column"},[_c('router-link',{attrs:{"to":"/supportSubmission"}},[_c('div',{staticClass:"notification is-info has-background-link"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('Submit a Support Ticket')))]),_c('vue-fontawesome',{staticClass:"icon-layout",attrs:{"icon":['fad', 'headset'],"size":"3x"}})],1)])],1),(_vm.leadentAccess)?_c('div',{staticClass:"column"},[_c('a',{attrs:{"href":"https://ubook-demo.leadentgateway.digital/","target":"_new"}},[_c('div',{staticClass:"notification is-info has-background-link"},[_c('p',{staticClass:"title"},[_vm._v("uBook "+_vm._s(_vm.$t('Web Demo')))]),_c('b-icon',{staticClass:"icon-layout",attrs:{"icon":'calendar',"size":"is-medium"}})],1)])]):_vm._e()])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }