<!--
  Screen to provide fast access to each piece of portal functionality
-->
<template>
  <div>
    <b-tabs
      v-model="selectedTab"
      size="is-medium"
      type="is-toggle-rounded"
      position="is-centered"
      class="has-background-white-ter"
      style="padding-top: 1rem; padding-bottom: 1rem"
    >
      <b-tab-item
        :label="$t('On My Way')"
        icon="map-marked-alt"
        icon-pack="fad"
        class="tab-item-spacing"
      >
        <div class="columns">
          <div class="column">
            <router-link
              :class="{
                disabled: !purchasedOmw || !(omwLookupAccess || adminAccess),
              }"
              to="/omwLookup"
            >
              <div
                class="notification is-info has-background-link"
                :class="{
                  disabled: !purchasedOmw || !(omwLookupAccess || adminAccess),
                }"
              >
                <p class="title">{{ $t('On My Way') }}</p>
                <vue-fontawesome
                  :icon="['fad', 'map-marked-alt']"
                  size="3x"
                  class="icon-layout"
                />
              </div>
            </router-link>

            <br />
            <div
              :class="{
                disabled:
                  !purchasedMarketing || !(marketingAccess || adminAccess),
              }"
            >
              <router-link
                to="/marketing"
                :class="{
                  disabled:
                    !purchasedMarketing || !(marketingAccess || adminAccess),
                }"
              >
                <div class="notification is-info has-background-link">
                  <p class="title">{{ $t('Marketing Messages') }}</p>
                  <vue-fontawesome
                    :icon="['fad', 'funnel-dollar']"
                    size="3x"
                    class="icon-layout"
                  />
                </div>
              </router-link>
            </div>
            <br />
            <div
              :class="{
                disabled:
                  !purchasedMarketing || !(marketingAccess || adminAccess),
              }"
            >
              <router-link
                to="/omw-kill-switch"
                :class="{
                  disabled:
                    !purchasedMarketing || !(marketingAccess || adminAccess),
                }"
              >
                <div class="notification is-info has-background-link">
                  <p class="title">{{ $t('Outage Config') }}</p>
                  <vue-fontawesome
                    :icon="['fad', 'unlink']"
                    size="3x"
                    class="icon-layout"
                  />
                </div>
              </router-link>
            </div>
          </div>
          <div class="column">
            <router-link
              :class="{
                disabled:
                  !purchasedFeedback || !(feedbackAccess || adminAccess),
              }"
              to="/feedbackViewer"
            >
              <div
                class="notification is-info has-background-link"
                :class="{
                  disabled:
                    !purchasedFeedback || !(feedbackAccess || adminAccess),
                }"
              >
                <p class="title">{{ $t('Customer Feedback') }}</p>
                <vue-fontawesome
                  :icon="['fad', 'comments']"
                  size="3x"
                  class="icon-layout"
                />
              </div>
            </router-link>
            <br />
            <router-link
              v-if="resourceConfigEnabled"
              :class="{
                disabled: !purchasedOmw || !(omwLookupAccess || adminAccess),
              }"
              to="/omwResourceConfig"
            >
              <div
                class="notification is-info has-background-link"
                :class="{
                  disabled: !purchasedOmw || !(omwLookupAccess || adminAccess),
                }"
              >
                <p class="title">{{ $t('Engineer Config') }}</p>
                <vue-fontawesome
                  :icon="['fad', 'user-hard-hat']"
                  size="3x"
                  class="icon-layout"
                />
              </div>
            </router-link>
          </div>
          <div class="column">
            <router-link
              :class="{
                disabled:
                  !purchasedOmwStats || !(omwStatsAccess || adminAccess),
              }"
              to="/omwStats"
            >
              <div
                class="notification is-info has-background-link"
                :class="{
                  disabled:
                    !purchasedOmwStats || !(omwStatsAccess || adminAccess),
                }"
              >
                <p class="title">{{ $t('On My Way Statistics') }}</p>
                <vue-fontawesome
                  :icon="['fad', 'chart-pie']"
                  size="3x"
                  class="icon-layout"
                />
              </div>
            </router-link>
            <br />
            <router-link
              :class="{
                disabled:
                  !purchasedOmwStats || !(omwStatsAccess || adminAccess),
              }"
              to="/rebookStats"
            >
              <div
                class="notification is-info has-background-link"
                :class="{
                  disabled:
                    !purchasedOmwStats || !(omwStatsAccess || adminAccess),
                }"
              >
                <p class="title">{{ $t('Rebook Statistics') }}</p>
                <vue-fontawesome
                  :icon="['fad', 'chart-pie']"
                  size="3x"
                  class="icon-layout"
                />
              </div>
            </router-link>
          </div>
        </div>
      </b-tab-item>
      <b-tab-item
        v-if="purchasedDataLoaders || purchasedActivityMigrator"
        :label="$t('OFSC Data Management')"
        icon="database"
        icon-pack="fad"
        class="tab-item-spacing"
      >
        <div class="columns">
          <div class="column">
            <router-link
              :class="{
                disabled:
                  !purchasedDataLoaders || !(dataLoaderAccess || adminAccess),
              }"
              to="/loaders"
            >
              <div
                class="notification is-info has-background-link"
                :class="{
                  disabled:
                    !purchasedDataLoaders || !(dataLoaderAccess || adminAccess),
                }"
              >
                <p class="title">{{ $t('OFSC Data Managers') }}</p>
                <vue-fontawesome
                  :icon="['fad', 'upload']"
                  size="3x"
                  class="icon-layout"
                />
              </div>
            </router-link>
            <br />
            <router-link
              :class="{
                disabled:
                  !purchasedDataLoaders || !(dataLoaderAccess || adminAccess),
              }"
              to="/extractCalendars"
            >
              <div
                class="notification is-info has-background-link"
                :class="{
                  disabled:
                    !purchasedDataLoaders || !(dataLoaderAccess || adminAccess),
                }"
              >
                <p class="title">{{ $t('Calendar Extract') }}</p>
                <vue-fontawesome
                  :icon="['fad', 'download']"
                  size="3x"
                  class="icon-layout"
                />
              </div>
            </router-link>
            <br />
            <router-link
              :class="{
                disabled:
                  !purchasedDataLoaders || !(dataLoaderAccess || adminAccess),
              }"
              to="/extractResources"
            >
              <div
                class="notification is-info has-background-link"
                :class="{
                  disabled:
                    !purchasedDataLoaders || !(dataLoaderAccess || adminAccess),
                }"
              >
                <p class="title">{{ $t('Resource Extract') }}</p>
                <vue-fontawesome
                  :icon="['fad', 'download']"
                  size="3x"
                  class="icon-layout"
                />
              </div>
            </router-link>
          </div>
          <div class="column">
            <router-link
              :class="{
                disabled:
                  !purchasedActivityMigrator ||
                  !(activityMigratorAccess || adminAccess),
              }"
              to="/activityMigrator"
            >
              <div
                class="notification is-info has-background-link"
                :class="{
                  disabled:
                    !purchasedActivityMigrator ||
                    !(activityMigratorAccess || adminAccess),
                }"
              >
                <p class="title">{{ $t('Activity Migrator') }}</p>
                <vue-fontawesome
                  :icon="['fad', 'download']"
                  size="3x"
                  class="icon-layout"
                />
              </div>
            </router-link>
            <br />
            <router-link
              :class="{
                disabled:
                  !purchasedInventoryManager ||
                  !(inventoryAccess || adminAccess),
              }"
              to="/inventoryManager"
            >
              <div
                class="notification is-info has-background-link"
                :class="{
                  disabled:
                    !purchasedInventoryManager ||
                    !(inventoryAccess || adminAccess),
                }"
              >
                <p class="title">{{ $t('Inventory Manager') }}</p>
                <p></p>
                <vue-fontawesome
                  :icon="['fad', 'inventory']"
                  size="3x"
                  class="icon-layout"
                />
              </div>
            </router-link>
          </div>

          <div class="column">
            <router-link
              :class="{
                disabled:
                  !purchasedRealTimeTravel ||
                  !(realTimeTravelAccess || adminAccess),
              }"
              to="/rtt"
            >
              <div
                class="notification is-info has-background-link"
                :class="{
                  disabled:
                    !purchasedRealTimeTravel ||
                    !(realTimeTravelAccess || adminAccess),
                }"
              >
                <p class="title">{{ $t('Real-Time Travel') }}</p>
                <vue-fontawesome
                  :icon="['fad', 'car-side']"
                  size="3x"
                  class="icon-layout"
                />
              </div>
            </router-link>
            <br />
            <router-link
              :class="{
                disabled: !purchasedPdflify || !(pdflifyAccess || adminAccess),
              }"
              to="/formSearch"
            >
              <div
                class="notification is-info has-background-link"
                :class="{
                  disabled:
                    !purchasedPdflify || !(pdflifyAccess || adminAccess),
                }"
              >
                <p class="title">{{ $t('Forms PDFlify') }}</p>
                <vue-fontawesome
                  :icon="['fad', 'clipboard-check']"
                  size="3x"
                  class="icon-layout"
                />
              </div>
            </router-link>
          </div>
        </div>
      </b-tab-item>
      <b-tab-item
        v-if="purchasedIsochrone"
        :label="$t('Resource Coverage')"
        icon="street-view"
        icon-pack="fad"
        class="tab-item-spacing"
      >
        <div class="columns">
          <div class="column">
            <router-link
              :class="{
                disabled:
                  !purchasedIsochrone || !(isochroneAccess || adminAccess),
              }"
              to="/isochrone"
            >
              <div
                class="notification is-info has-background-dark"
                :class="{
                  disabled:
                    !purchasedIsochrone || !(isochroneAccess || adminAccess),
                }"
              >
                <p class="title">{{ $t('Coverage Analytics') }}</p>
                <vue-fontawesome
                  :icon="['fad', 'street-view']"
                  size="3x"
                  class="icon-layout"
                />
              </div>
            </router-link>
          </div>
          <div class="column"></div>
        </div>
      </b-tab-item>
      <b-tab-item
        :label="$t('Administration')"
        icon="tools"
        icon-pack="fad"
        class="tab-item-spacing"
      >
        <div class="columns">
          <div v-if="adminAccess || customerAdminAccess" class="column">
            <router-link to="/admin">
              <div class="notification is-info has-background-link">
                <p class="title">{{ $t('User Admin') }}</p>
                <vue-fontawesome
                  :icon="['fad', 'lock']"
                  size="3x"
                  class="icon-layout"
                />
              </div>
            </router-link>
          </div>
          <div class="column">
            <router-link to="/envAdmin">
              <div class="notification is-info has-background-link">
                <p class="title">{{ $t('Environment Admin') }}</p>
                <vue-fontawesome
                  :icon="['fad', 'database']"
                  size="3x"
                  class="icon-layout"
                />
              </div>
            </router-link>
          </div>
          <div class="column">
            <router-link to="/supportSubmission">
              <div class="notification is-info has-background-link">
                <p class="title">{{ $t('Submit a Support Ticket') }}</p>
                <vue-fontawesome
                  :icon="['fad', 'headset']"
                  size="3x"
                  class="icon-layout"
                />
              </div>
            </router-link>
          </div>
          <div v-if="leadentAccess" class="column">
            <a href="https://ubook-demo.leadentgateway.digital/" target="_new">
              <div class="notification is-info has-background-link">
                <p class="title">uBook {{ $t('Web Demo') }}</p>
                <b-icon
                  :icon="'calendar'"
                  size="is-medium"
                  class="icon-layout"
                ></b-icon>
              </div>
            </a>
          </div>
        </div>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { mapGetters, mapActions } from 'vuex';

export default defineComponent({
  name: 'MainDashboard',
  computed: {
    ...mapGetters('storeAuth', ['userId']),
    selectedTab: {
      get() {
        return this.$store.getters['storeAuth/selectedTab'];
      },
      set(val) {
        this.setSelectedTab(val);
      },
    },
  },
  created() {
    this.fetchUser(this.userId);
  },
  methods: {
    ...mapActions('storeAuth', ['fetchUser', 'setSelectedTab']),
  },
});
</script>

<style scoped>
.disabled-tile {
  opacity: 0.1% !important;
}

h1,
p {
  text-align: center;
}
p {
  color: red;
}
.notification a:not(.button) {
  text-decoration: none;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.icon-layout {
  display: block;
  margin: 0 auto;
}

.tab-item-spacing {
  padding-top: 1rem;
}
</style>
